import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';
import { BG_PRIMARY } from '@/constants/colors';
import { TITLE_SECTION_STYLES, HEADING_STYLES, CONTENT_STYLES } from '@/constants/typography'

import WithAnimation from '@/components/Common/WithAnimation';

function LeftContent() {
  return (
    <Box
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      bgPosition="center"
      position="relative"
      width="calc(100% - 500px)"
      pos="fixed"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
      height="100%"
      zIndex="10"
    >
      <Box padding="24px 42px" height="100%"
        bgImage={`linear-gradient(to bottom right, ${BG_PRIMARY}, transparent 60%)`}>
        <WithAnimation>
          <Text  
            {...TITLE_SECTION_STYLES}
            fontSize="40px"
            color="white"
            textAlign="left"
            textTransform="lowercase"
            >
            The Wedding of
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Text
            margin="24px 0 32px 0"
            {...HEADING_STYLES}
            color="white"
            fontSize="60px"
          >
            {THE_BRIDE}
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Box width="450px">
            <Text {...CONTENT_STYLES} color="white" fontSize="18px" fontStyle="italic">
              “Semoga Allah memberkahimu ketika bahagia dan ketika susah, serta mengumpulkan kalian berdua dalam kebaikan.”
            </Text>
            <Text {...CONTENT_STYLES} color="white" fontSize="18px" pt="12px" fontStyle="italic">
              (HR. Abu Daud: 2130)
            </Text>
          </Box>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default LeftContent;
